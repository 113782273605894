<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>{{ title }}</template>
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <CustomSelectComponent
      label="Je souhaite"
      :options="[
        { value: 'ANNULATION', text: 'annuler' },
        { value: 'TRANSFERT', text: 'transférer' },
      ]"
      rules="required"
      v-model="mode"
    ></CustomSelectComponent>
    <SelectRecolteComponent
      ref="selectRecolte"
      label="les déclarations de la campagne"
      rules="required"
      v-model="codeRecolte"
    ></SelectRecolteComponent>
    <SelectTiersComponent
      ref="selectTiersOrigine"
      label="du tiers"
      rules="required"
      v-model="codeTiersOrigine"
    ></SelectTiersComponent>
    <SelectTiersComponent
      v-if="mode === 'TRANSFERT'"
      ref="selectTiersDestination"
      label="au tiers"
      rules="required"
      v-model="codeTiersDestination"
    ></SelectTiersComponent>
    <WarningBloc v-if="mode === 'TRANSFERT'" class="mt-3">
      Le tiers cible ne doit avoir aucune parcelle initialis&eacute;e pour la
      campagne concern&eacute;e.
    </WarningBloc>
    <WarningBloc v-else class="mt-3">
      Cette action supprimera tout le parcellaire du tiers pour la campagne
      concern&eacute;e.
    </WarningBloc>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import ErrorComponent from "../ErrorComponent.vue";
import WarningBloc from "../blocs/WarningBloc.vue";
import CustomSelectComponent from "../inputs/CustomSelectComponent.vue";
import SelectRecolteComponent from "../inputs/SelectRecolteComponent.vue";
import SelectTiersComponent from "../inputs/SelectTiersComponent.vue";
import DeclarationsService from "../../services/declarations.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "TransfertExploitationDialog",
  components: {
    ErrorComponent,
    WarningBloc,
    CustomSelectComponent,
    SelectRecolteComponent,
    SelectTiersComponent,
  },
  data: () => ({
    error: null,
    mode: "ANNULATION",
    codeRecolte: null,
    codeTiersOrigine: null,
    codeTiersDestination: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  computed: {
    title() {
      if (this.mode === "TRANSFERT") {
        return "Transfert de déclarations";
      } else {
        return "Annulation de déclarations";
      }
    },
  },
  methods: {
    show(options) {
      this.mode = options?.mode === "TRANSFERT" ? "TRANSFERT" : "ANNULATION";
      this.codeRecolte = options?.codeRecolte ?? null;
      this.codeTiersOrigine = options?.codeTiersOrigine ?? null;
      this.codeTiersDestination = options?.codeTiersDestination ?? null;
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async validate() {
      await this.$refs.selectRecolte.validate();
      await this.$refs.selectTiersOrigine.validate();
      await this.$refs.selectTiersDestination?.validate();

      try {
        if (this.mode === "TRANSFERT") {
          await DeclarationsService.transfererDeclarations(
            this.codeRecolte,
            this.codeTiersOrigine,
            this.codeTiersDestination
          );
        } else {
          await DeclarationsService.annulerDeclarations(
            this.codeRecolte,
            this.codeTiersOrigine
          );
        }
      } catch (error) {
        this.error = UtilsService.handleError(error);
        throw error;
      }

      this.$refs["modal"].hide();
      this.resolvePromise(true);
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
