<template>
  <span v-if="sanitizedValue !== ''">
    <b-icon-upc-scan></b-icon-upc-scan>
    {{ sanitizedValue }}
  </span>
</template>

<script>
export default {
  name: "SiretNumberComponent",
  props: ["value"],
  computed: {
    sanitizedValue() {
      // 9 = SIREN ; 14 = SIREN + NIC
      if (this.value?.length === 9 || this.value?.length === 14) {
        let sanitized = "";
        for (let i = 0; i < this.value.length; i++) {
          if (i === 3 || i === 6 || i === 9) {
            sanitized += " ";
          }
          sanitized += this.value[i];
        }
        return sanitized;
      }
      return this.value;
    },
  },
};
</script>

<style scoped></style>