<template>
  <section :id="`container-${id}`">
    <b-form-group
      :id="`group-${id}`"
      :label="computedLabel"
      :label-for="`input-${id}`"
    >
      <b-input-group>
        <b-form-input
          :ref="`input-${id}`"
          :id="`input-${id}`"
          :name="`input-${id}`"
          type="text"
          :state="state"
          :value="libelle"
          @input="onChange"
          @change="onChange"
        ></b-form-input>
        <b-input-group-append>
          <b-button variant="danger" @click="onClose">
            <b-icon-x-lg></b-icon-x-lg>
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-popover
        :target="`input-${id}`"
        triggers=""
        :show="show"
        container="container"
        placement="auto"
        ref="popover"
      >
        <template #title>
          <b-button class="close" aria-label="Close" @click="onClose">
            &times;
          </b-button>
          S&eacute;lectionner un adh&eacute;rent
        </template>
        <b-container style="cursor: pointer">
          <b-row v-for="option in options" :key="option.value">
            <b-col @click="onClick(option)">
              {{ option.text }}
            </b-col>
          </b-row>
        </b-container>
      </b-popover>
    </b-form-group>
  </section>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import UtilsService from "../../services/utils.service";
export default {
  name: "SelectTiersComponent",
  /**
   * value = valeur du select (code tiers)
   * label = valeur du label ("Tiers" par défaut)
   */
  props: ["value", "label"],
  data() {
    return {
      id: `tiers-${uuidv4()}`,
      libelle: "",
      state: null,
      show: false,
    };
  },
  computed: {
    computedLabel() {
      return this.label ?? "Tiers";
    },
    options() {
      return this.$store.state.expl.portefeuille
        .map((t) => ({
          value: t.codeTiers,
          text: `${t.raisonSociale} (${t.codeTiers})`,
        }))
        .filter(
          (o) =>
            !this.libelle ||
            o.text.toLowerCase().includes(this.libelle.toLowerCase())
        )
        .sort(UtilsService.sortByStringProperty("text"))
        .slice(0, 10);
    },
  },
  mounted() {
    let t = null;
    if (this.value) {
      t = this.$store.getters["expl/getExploitation"](this.value);
    }
    this.libelle = t ? `${t.raisonSociale} (${t.codeTiers})` : "";
  },
  methods: {
    async onChange(libelle) {
      this.libelle = libelle;
      await this.$nextTick();
      this.show = this.options.length > 0;
    },
    onClose() {
      this.show = false;
      this.libelle = "";
      return this.$emit("input", null);
    },
    onClick(option) {
      this.show = false;
      this.libelle = option.text;
      return this.$emit("input", option.value);
    },
    async validate() {
      if (this.value != null) {
        this.state = true;
        return;
      }

      this.state = false;
      this.focus();
      throw new Error("Invalid field");
    },
    focus() {
      this.$refs[`input-${this.id}`].$el.focus();
    },
  },
};
</script>
