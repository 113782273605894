<template>
  <section>
    <TitleBar>Bonjour {{ prenomNom }}</TitleBar>
    <b-container fluid>
      <p class="mt-3">
        Vous &ecirc;tes actuellement connect&eacute;(e) &agrave; l'exploitation
        :
      </p>
      <ExploitationComponent :item="adherentCourant"></ExploitationComponent>
      <section v-if="exploitations.length > 1">
        <p class="mt-3">
          Vous pouvez acc&eacute;der &agrave; une autre de vos exploitations ici
          :
        </p>
        <ul>
          <li
            v-for="exploitation in exploitations"
            :key="exploitation.codeTiers"
          >
            <b-link
              href="#"
              @click="selectExploitation(exploitation.codeTiers)"
            >
              {{ exploitation.raisonSociale }} ({{ exploitation.codeTiers }})
            </b-link>
            <b-icon-check-circle-fill
              v-if="exploitation.codeTiers === exploitationCourante.codeTiers"
              variant="success"
            ></b-icon-check-circle-fill>
          </li>
        </ul>
      </section>
      <section v-if="isSalarie">
        <hr />
        <b-row>
          <b-col cols="12" md="6">
            <h3>Mes adh&eacute;rents</h3>
            <p>
              Vous pouvez acc&eacute;der aux exploitations de votre
              coop&eacute;rative ici (pour revenir &agrave; votre exploitation,
              utiliser le bouton rouge) :
            </p>
            <SelectTiersComponent
              label=""
              :value="$store.state.expl.adherentCourant"
              @input="(codeTiers) => selectAdherent(codeTiers)"
            ></SelectTiersComponent>
          </b-col>
          <b-col class="mt-3 mt-md-0" cols="12" md="6">
            <h3>Fermeture/transfert d'exploitation</h3>
            <p>
              Vous pouvez annuler les d&eacute;clarations d'un adh&eacute;rent
              pour la campagne de votre choix, ou les transf&eacute;rer vers une
              autre exploitation ici :
            </p>
            <b-row>
              <b-col>
                <b-button
                  block
                  pill
                  variant="primary"
                  @click="openAnnulationDialog"
                >
                  Annulation
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  block
                  pill
                  variant="primary"
                  @click="openTransfertDialog"
                >
                  Transfert
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <TransfertExploitationDialog
          ref="transfertExploitationDialog"
        ></TransfertExploitationDialog>
      </section>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import ExploitationComponent from "../../components/ExploitationComponent.vue";
import SelectTiersComponent from "../../components/inputs/SelectTiersComponent.vue";
import TransfertExploitationDialog from "../../components/dialogs/TransfertExploitationDialog.vue";
export default {
  name: "ExploitationsView",
  components: {
    TitleBar,
    ExploitationComponent,
    SelectTiersComponent,
    TransfertExploitationDialog,
  },
  computed: {
    exploitations() {
      return this.$store.state.expl.exploitations;
    },
    exploitationCourante() {
      return this.$store.getters["expl/exploitationCourante"] || {};
    },
    adherentCourant() {
      return this.$store.getters["expl/adherentCourant"] || {};
    },
    isSalarie() {
      return this.$store.getters["auth/isSalarie"];
    },
    prenomNom() {
      return this.$store.getters["expl/prenomNom"];
    },
  },
  methods: {
    selectExploitation(codeTiers) {
      this.$store.commit("expl/setExploitationCourante", codeTiers);
    },
    selectAdherent(codeTiers) {
      this.$store.commit("expl/setAdherentCourant", codeTiers);
    },
    openAnnulationDialog() {
      this.$refs.transfertExploitationDialog.show({ mode: "ANNULATION" });
    },
    openTransfertDialog() {
      this.$refs.transfertExploitationDialog.show({ mode: "TRANSFERT" });
    },
  },
};
</script>
