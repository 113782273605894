<template>
  <b-card>
    <b-card-title>
      {{ item.raisonSociale }} ({{ item.codeTiers }})
    </b-card-title>
    <b-card-sub-title class="mb-3">
      <SiretNumberComponent :value="item.numeroSiret"></SiretNumberComponent>
      <template v-if="item.dateAdhesion">
        - Adh&eacute;rent depuis le {{ dateAdhesion }}
      </template>
    </b-card-sub-title>
    <b-card-text>
      <AddressComponent
        :value="item.getAdresseComplete()"
        :icon="true"
      ></AddressComponent>
    </b-card-text>
    <b-card-text>
      <PhoneNumberComponent
        class="mr-3"
        :value="item.telephoneFixe"
        :icon="true"
      ></PhoneNumberComponent>
      <PhoneNumberComponent
        class="mr-3"
        :value="item.telephonePortable"
        :icon="true"
      ></PhoneNumberComponent>
      <PhoneNumberComponent
        :value="item.telephoneFax"
        :icon="true"
      ></PhoneNumberComponent>
    </b-card-text>
    <b-card-text v-if="contacts.length > 0">
      <h4 class="d-inline-block mr-3">Contacts</h4>
      <b-link v-if="showContacts" href="#" @click="showContacts = false">
        Masquer
        <b-icon-dash></b-icon-dash>
      </b-link>
      <b-link v-else href="#" @click="showContacts = true">
        Afficher
        <b-icon-plus></b-icon-plus>
      </b-link>
      <template v-if="showContacts">
        <ul>
          <li v-for="contact in contacts" :key="contact.codeContact">
            <strong>{{ contact.prenom }} {{ contact.nom }}</strong>
            &nbsp;
            <b-icon-check-circle-fill
              v-if="contact.actif"
              variant="success"
            ></b-icon-check-circle-fill>
            <b-icon-x-circle-fill
              v-else
              variant="danger"
            ></b-icon-x-circle-fill>
            &nbsp;
            <EmailComponent
              :value="contact.email"
              :icon="true"
            ></EmailComponent>
            &nbsp;
            <PhoneNumberComponent
              :value="contact.telephoneFixe"
              :icon="true"
            ></PhoneNumberComponent>
            &nbsp;
            <PhoneNumberComponent
              :value="contact.telephonePortable"
              :icon="true"
            ></PhoneNumberComponent>
          </li>
        </ul>
      </template>
    </b-card-text>
    <b-card-text v-if="criteresBeef.length > 0">
      <h4 class="d-inline-block mr-3">Objectifs Bio Equitable en France</h4>
      <b-link
        v-if="showCriteresBeef"
        href="#"
        @click="showCriteresBeef = false"
      >
        Masquer
        <b-icon-dash></b-icon-dash>
      </b-link>
      <b-link v-else href="#" @click="showCriteresBeef = true">
        Afficher
        <b-icon-plus></b-icon-plus>
      </b-link>
      <template v-if="showCriteresBeef">
        <b-card-sub-title v-if="item.dateDebutBeef" class="mb-3">
          Choix au {{ dateDebutBeef }}, engagement jusqu'au {{ dateFinBeef }}
        </b-card-sub-title>
        <ul v-if="criteresBeef[0].selectionne == null">
          <li v-for="critere in criteresBeef" :key="critere.codeCritere">
            {{ critere.libelle }}
          </li>
        </ul>
        <div v-else>
          <b-form-checkbox
            v-for="critere in criteresBeef"
            :key="critere.codeCritere"
            :checked="critere.selectionne"
            :disabled="true"
          >
            {{ critere.libelle }}
          </b-form-checkbox>
        </div>
      </template>
    </b-card-text>
  </b-card>
</template>

<script>
import AddressComponent from "./AddressComponent.vue";
import PhoneNumberComponent from "./PhoneNumberComponent.vue";
import SiretNumberComponent from "./SiretNumberComponent.vue";
import EmailComponent from "./EmailComponent.vue";
import ExploitationService from "../services/exploitation.service";
import UtilsService from "../services/utils.service";
export default {
  components: {
    AddressComponent,
    PhoneNumberComponent,
    SiretNumberComponent,
    EmailComponent,
  },
  name: "ExploitationComponent",
  props: ["item"],
  watch: {
    item: "load",
  },
  data: () => ({
    showContacts: false,
    contacts: [],
    showCriteresBeef: false,
    criteresBeef: [],
  }),
  computed: {
    dateAdhesion() {
      return UtilsService.isoSqlDateToFrenchDate(this.item.dateAdhesion);
    },
    dateDebutBeef() {
      return UtilsService.isoSqlDateToFrenchDate(this.item.dateDebutBeef);
    },
    dateFinBeef() {
      return UtilsService.isoSqlDateToFrenchDate(this.item.dateFinBeef);
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      await this.loadContacts();
      await this.loadCriteresBeef();
    },
    async loadContacts() {
      this.contacts = [];
      let response = await ExploitationService.getContacts(this.item.codeTiers);
      this.contacts = response.data;
    },
    async loadCriteresBeef() {
      this.criteresBeef = [];
      let response = await ExploitationService.getCriteresBeef(
        this.item.codeTiers
      );
      this.criteresBeef = response.data.sort(
        UtilsService.sortByNumberProperty(
          `parametrages.${this.item.codeSociete}.ordre`
        )
      );
    },
  },
};
</script>
